<template>
  <div>
    <ul class="multiple-image">
      <li v-if="fileUrl">
        <!-- <img :src="fileUrl" alt="背景图片"> -->
        <i class="el-icon-paperclip"></i>
        <div class="close el-icon-circle-close" @click="remove()"></div>
      </li>
      <li class="fileupload" v-else>
        <input name="file" type='file' @change='change($event)' :disabled="disabled" accept="video/mpeg,video/mp4"/>
        <span class="el-icon-upload">请上传MP4文件</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { imageUploadRequest } from '@/api/upload'
export default {
  name: 'fileUpload',
  props: {
    file: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fileUrl: '',
    }
  },
  computed: {
    singlefileUrl () {
      if (!this.multiple) {
        return this.fileUrl
      }
      return ''
    }
  },
  watch: {
    file (newVal) {
      if (newVal) {
        this.fileUrl = newVal
      }else{
        this.fileUrl = '';
      }
    }
  },
  created () {
    this.fileUrl = this.file
  },
  methods: {
    async change (e) {
      if (!e.currentTarget.files.length) {
        return
      }
      let fileArr = e.currentTarget.files
      for (let i = 0;i < fileArr.length;i++) {
        console.log(fileArr[i]);
        if (fileArr[i].type.indexOf("video/mp4") < 0 ) {
          this.$message.warning("只能上传MP4文件！")
          return
        }
        let data = new FormData()
        data.append('file', fileArr[i])
        await imageUploadRequest(data).then(res => {
          this.fileUrl = res.data.accessAddress;
          this.multiple = true;
        })
      }
      this.$emit('upload', this.fileUrl)
    },
    remove () {
      this.fileUrl = "";
         this.$emit('upload', "")
    }
  }
}
</script>
<style lang="less" scoped>
  .multiple-image {
    display: flex;
    padding: 0;
    li {
      display: flex;
      width: 300px;
      align-items: center;
      justify-content: center;
      border: 1px dashed #dcdfe6;
      border-radius: 5px;
      margin-right: 15px;
      position: relative;
    }
    i{
      display: block;
      height: 123px;
      width: 300px;
      font-size: 50px;
      color: #409EFF;
      line-height: 123px;
    }
    .fileupload {
      position: relative;
    }
    .close {
      position: absolute;
      right: -15px;
      top: -15px;
      font-size: 30px;
      background: #fff;
      color: #666;
    }
  }
  .fileupload {
    display: inline-block;
    width: 100%;
    border: 1px dashed #dcdfe6;
    border-radius: 5px;
    cursor: pointer;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    vertical-align: middle;
    height: 125px !important;
    line-height: 125px !important;
    span {
      font-size: 15px;
      vertical-align: middle;
      color: #c0c4cc;
      &::before {
        font-size: 30px;
        margin-right: 10px;
      }
    }
    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
  .is-error {
    .fileupload {
      border-color: #f56c6c;
    }
  }
</style>
