// js去掉url中的域名
// 去除域名
export function removeUrl(url) {

  if (!url) {
    return '';
  }

  const type = url.split(':')[0]
  if (type === 'https') {
    url = url.replace(/^https:\/\/[^/]+/, "")
  } else {
    url = url.replace(/^http:\/\/[^/]+/, "")
  }
  return url;
}
// 转换图片数组
const ImgListFormat = (url) => {
  if (!url) {
    return [];
  }


  let list = url.split(',');
  let imgList = [];
  list.forEach(item => {
    imgList.push(item)
  });
  return imgList;
}
// 上传文件格式
const uploadImgFormat = (list) => {
  console.log(list)
  if (!list) {
    return '';
  }

  let url = "";
  list.forEach(item => {
    url = url + removeUrl(item) + ','
  });
  return url
}
export default {
  removeUrl, // js去掉url中的域名
  ImgListFormat,
  uploadImgFormat
}