<template>
  <div class="create-ad">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>CMS模块</el-breadcrumb-item>
            <el-breadcrumb-item>文章列表</el-breadcrumb-item>
            <el-breadcrumb-item>添加文章</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-form class="form-container" ref="form" :model="form" label-width="140px">
        <el-form-item label="序号" :error="$v.form.sorder.$error ? '请输入序号' : ''">
          <el-input v-model.number="form.sorder" type="number"></el-input>
        </el-form-item>
        <el-form-item label="所属分类" :error="$v.form.articleTypeId.$error ? '请输入选择所属分类' : ''">
          <el-cascader :options="classify" :props="{ checkStrictly: true }" clearable v-model="form.articleTypeId"
            @change="changeClassify"></el-cascader>
        </el-form-item>
        <el-form-item label="标题" :error="$v.form.title.$error ? '请输入标题' : ''">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="缩略图">
          <image-upload @upload="imageUpload" :image="form.thumbnailImg" />
        </el-form-item>
        <el-form-item label="关键字">
          <el-input v-model="form.keyWord"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="form.describe"></el-input>
        </el-form-item>
        <el-form-item label="视频文件" :file="form.audioFile">
          <file-upload @upload="fileUpload" />
        </el-form-item>
        <el-form-item label="视频URL">
          <el-input v-model="form.audioFile"></el-input>
        </el-form-item>
        <el-form-item label="视频预览" v-if="form.audioFile != ''">
          <video controls="controls" :src="form.audioFile" width="600px" />
        </el-form-item>
        <el-form-item label="虚拟访问量" :error="$v.form.virtualTraffic.$error ? '请输入虚拟访问量' : ''">
          <el-input v-model.number="form.virtualTraffic" type="number"></el-input>
        </el-form-item>
        <el-form-item class="block-item" label="文章正文">
          <tinymce v-model="form.contents" />
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="form.states">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="createAd">添加</el-button>
          <el-button @click="back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Tinymce from "@/components/tinymce";
import ImageUpload from "@/components/imageUpload";
import FileUpload from "@/components/fileUpload";
import { addarticleRequest, getSelectRequest } from "@/api/article";
import { required } from "vuelidate/lib/validators";
import utils from "@/utils/util";
export default {
  name: "Articletypecreate",
  data() {
    return {
      classify: [],
      form: {
        id: 0,
        articleTypeId: "",
        keyWord: "",
        describe: "",
        thumbnailImg: "",
        audioFile: "",
        states: 1,
        sorder: 0,
        visitsNum: 0,
        virtualTraffic: 0,
        contents: ""
      }
    };
  },
  components: {
    "image-upload": ImageUpload,
    "file-upload": FileUpload,
    tinymce: Tinymce
  },
  created() {
    this.getSelect();
    this.form.articleTypeId = this.$route.query.articleTypeId;
  },
  methods: {
    back() {
      this.$router.back();
    },
    changeClassify(e) {
      this.form.articleTypeId = e[e.length - 1];
    },
    getSelect() {
      getSelectRequest().then(res => {
        console.log(res);
        this.classify = res.data;
      });
    },
    imageUpload(url) {
      this.form.thumbnailImg = url;
    },
    fileUpload(url) {
      console.info(url);
      this.form.audioFile = url;
    },
    createAd() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        let params = JSON.parse(JSON.stringify(this.form));
        console.log(params);
        params.states = params.states == true ? 1 : 0;
        params.thumbnailImg = utils.removeUrl(params.thumbnailImg);
        params.audioFile = utils.removeUrl(params.audioFile);
        addarticleRequest(params).then(res => {
          if (res.code == 1) {
            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    }
  },
  validations: {
    form: {
      title: {
        required
      },
      articleTypeId: {
        required
      },

      sorder: {
        required
      },
      virtualTraffic: {
        required
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-cascader .el-input input {
  width: 460px;
}
</style>
