import request from '@/utils/request'

// 获取文章列表
export function getListRequest(params) {
  return request({
    url: '/admin/SysArticleList/getList',
    method: 'get',
    params
  })
}

// 添加文章
export function addarticleRequest(data) {
  return request({
    url: '/admin/SysArticleList/add',
    method: 'post',
    data
  })
}

// 修改状态
export function setStatesRequest(params) {
  return request({
    url: '/admin/SysArticleList/setStates',
    method: 'get',
    params
  })
}

// 级联选择列表
export function getSelectRequest(params) {
  return request({
    url: '/admin/SysArticleType/GetSelect',
    method: 'get',
    params
  })
}

// 获取文章详情
export function getdatilRequest(params) {
  return request({
    url: '/admin/SysArticleList/getdatil',
    method: 'get',
    params
  })
}

// 删除文章
export function delarticleRequest(params) {
  return request({
    url: '/admin/SysArticleList/del',
    method: 'get',
    params
  })
}

// 修改文章
export function editarticleRequest(data) {
  return request({
    url: '/admin/SysArticleList/edit',
    method: 'post',
    data
  })
}

// 修改排序
export function setSortRequest(params) {
  return request({
    url: '/admin/SysArticleList/setSort',
    method: 'get',
    params
  })
}